<template>
    <div>
        <div class="nfs-create-issue">
            <NuxtLinkLocale to="/issue-form">
                <button
                    v-if="canCreateIssue"
                    class="rounded-full bg-brand-300 p-3 shadow-sm hover:bg-brand-400"
                >
                    <NfsIcon
                        icon="icon-plus"
                        size="medium"
                    />
                </button>
                <button
                    v-else
                    v-tooltip="$t('TOOLTIP_MESSAGE.LOGIN_TO_CREATE_ISSUE')"
                    class="rounded-full bg-brand-300 p-3 opacity-50 shadow-sm"
                    disabled="true"
                >
                    <NfsIcon
                        icon="icon-plus"
                        size="medium"
                    />
                </button>
            </NuxtLinkLocale>
        </div>
        <slot />
    </div>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { UserPermissionType } from '../../shared/shared.interface';
import { useUserHasPermissions } from '../composables/userPermissions';
const auth0 = process.client ? useAuth0() : undefined;
const layer2 = useRuntimeConfig().public.appLayout.zIndex.layer2;
const canCreateIssue = ref(false);
canCreateIssue.value = await isUserPermitted([ UserPermissionType.CREATE_ISSUE ]);

watchEffect(async() => {
    if (auth0?.isAuthenticated.value)
        canCreateIssue.value = await isUserPermitted([ UserPermissionType.CREATE_ISSUE ]);
});

async function getAuthToken() {
    if (!auth0 || !auth0?.isAuthenticated || !auth0?.isAuthenticated.value)
        return null;

    return await auth0!.getAccessTokenSilently();
}

async function isUserPermitted(requiredPermissions:string[]) {
    const jwtToken = await getAuthToken();
    if (jwtToken)
        return useUserHasPermissions(jwtToken, requiredPermissions);
    else
        return false;
}
</script>

<style scoped>
.nfs-create-issue {
 @apply fixed bottom-8 right-1 z-[v-bind(layer2)]
}
</style>
